import React, { Component } from 'react';

class Error extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return (<h2>Fehler 404</h2>);
    }
}
 
export default Error;