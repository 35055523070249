import React, { Component } from 'react';

import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import MELogo from '../images/me-logo.png';
import MEEvents from '../images/me-events.png';
import OldenboraLogo from '../images/oldenbora-logo.jpg';
import AirbeatLogo from '../images/airbeatone-logo.png';
import BeachLogo from '../images/beachclub-nethen.jpg';
import NightLogo from '../images/night-of-freestyle-logo.png';
import IndianLogo from '../images/indian-spirit-logo.png';
import HolyLogo from '../images/holy-taco-logo.png';
import PsyCircus from '../images/psychedelic-circus-logo.png';

class Partner extends Component {
    constructor(props) {
        SwiperCore.use([Autoplay]);
        super(props);
        this.state = {
            partners: [
                { title: "Music Eggert", url: 'https://www.musiceggert.de/', logo: MELogo },
                { title: "Oldenbora", url: 'https://oldenbora.de/', logo: OldenboraLogo },
                { title: "Airbeat One", url: 'https://airbeat-one.de/', logo: AirbeatLogo },
                { title: "Beachclub Nethen", url: 'https://beachclub-nethen.de/', logo: BeachLogo },
                { title: "Night of Freestyle", url: 'https://www.nightoffreestyle.de/', logo: NightLogo },
                { title: "Indian Spirit", url: 'https://www.indian-spirit.de/', logo: IndianLogo },
                { title: "Holy Taco", url: 'https://www.holy-taco.de/', logo: HolyLogo },
                { title: "Psychedelic Circus", url: 'https://www.psychedelic-circus.de/', logo: PsyCircus },
                { title: "ME Events", url: 'https://www.me-events.de/', logo: MEEvents },
            ]
        }
    }
    render() { 
        return (
            <div className="partner-wrapper">
                <Swiper
                    loop={true}
                    spaceBetween={30}
                    slidesPerView={2}
                    autoplay={{
                        delay: 0,
                        disableOnInteraction: false
                    }}
                    speed={1000}
                    breakpoints={{
                        767: {
                            slidesPerView: 4
                        },
                        992: {
                            slidesPerView: 6
                        }
                    }}
                    >
                    {this.state.partners.map((partner) =>
                        <SwiperSlide className="partner-item">
                            <div className="partner-inner">
                                <a href={partner.url} title={partner.title} target="_blank">
                                    <img src={partner.logo} alt={partner.title + " Logo"}/>
                                </a>
                            </div>
                        </SwiperSlide>
                    )}
                </Swiper>
            </div>
        );
    }
}
 
export default Partner;