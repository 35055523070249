import './scss/main.scss';
import 'normalize.css';
import {Helmet} from "react-helmet";
import Error from './components/Error';
import Intro from './components/Intro';
import Contact from './components/Contact';
import Header from './components/Header';
import AboutUs from './components/AboutUs';
import Footer from './components/Footer';
import Privacy from './components/Privacy';
import Imprint from './components/Imprint';
import Service from './components/Service';
import Blog from './components/Blog';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import styles from './App.css';
import cn from 'classnames';

let wrapperClass = '';

function checkPath() {
  let pathName = window.location.pathname;
  if( pathName == '/' ) {
      pathName = 'startseite';
  }
  wrapperClass = pathName.replace(/\\|\//g,'');
}

checkPath();

function App() {
  return (
    <div className={'App ' + wrapperClass}>
      <Header/>
      <Router>
          <Switch>
            <Route path="/kontakt">
              <Helmet>
                  <meta charSet="utf-8" />
                  <title>Kontakt - MOHN.EVENTS</title>
                  <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
                  <meta name="description" content="Zögern Sie nicht uns zu kontaktieren. Sie brauchen Unterstützung oder wollen sich weiterentwickeln? Dann zögern Sie nicht uns zu schreiben."/>
                  <meta name="robots" content="index, follow, archive"/>
                  <meta property="st:section" content="Zögern Sie nicht uns zu kontaktieren. Sie brauchen Unterstützung oder wollen sich weiterentwickeln? Dann zögern Sie nicht uns zu schreiben."/>
                  <meta name="twitter:title" content="Kontakt"/>
                  <meta name="twitter:description" content="Zögern Sie nicht uns zu kontaktieren. Sie brauchen Unterstützung oder wollen sich weiterentwickeln? Dann zögern Sie nicht uns zu schreiben."/>
                  <meta name="twitter:card" content="summary_large_image"/>
                  <meta property="og:url" content="https://www.mohn.events/kontakt/"/>
                  <meta property="og:title" content="Kontakt"/>
                  <meta property="og:description" content="Zögern Sie nicht uns zu kontaktieren. Sie brauchen Unterstützung oder wollen sich weiterentwickeln? Dann zögern Sie nicht uns zu schreiben."/>
                  <meta property="og:type" content="website"/>
                  <meta property="og:locale" content="de_DE"/>
                  <meta property="og:site_name" content="MOHN.EVENTS"/>
              </Helmet>
              <main id="content" class="kontakt">
                <Contact />
              </main>
            </Route>
            <Route path="/service">
              <Helmet>
                <meta charSet="utf-8" />
                <title>Service - MOHN.EVENTS</title>
                <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
                <meta name="description" content="Service - Service Übersicht von MOHN.EVENTS inklusive Referenzen"/>
                <meta name="robots" content="index, follow, archive"/>
                <meta property="st:section" content="Service - Service Übersicht von MOHN.EVENTS inklusive Referenzen"/>
                <meta name="twitter:title" content="Service"/>
                <meta name="twitter:description" content="Service - Service Übersicht von MOHN.EVENTS inklusive Referenzen"/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta property="og:url" content="https://www.mohn.events/service"/>
                <meta property="og:title" content="Service"/>
                <meta property="og:description" content="Service - Service Übersicht von MOHN.EVENTS inklusive Referenzen"/>
                <meta property="og:type" content="website"/>
                <meta property="og:locale" content="de_DE"/>
              </Helmet>
              <main id="content" class="service">
                <Service />
              </main>
            </Route>
            <Route path="/ueber-uns">
              <Helmet>
                <meta charSet="utf-8" />
                <title>Über Uns - MOHN.EVENTS</title>
                <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
                <meta name="description" content="Über Uns - Erfahren Sie mehr über uns und unsere Leistungen"/>
                <meta name="robots" content="index, follow, archive"/>
                <meta property="st:section" content="Über Uns - Erfahren Sie mehr über uns und unsere Leistungen"/>
                <meta name="twitter:title" content="Über Uns"/>
                <meta name="twitter:description" content="Über Uns - Erfahren Sie mehr über uns und unsere Leistungen"/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta property="og:url" content="https://www.mohn.events/ueber-uns"/>
                <meta property="og:title" content="Über Uns"/>
                <meta property="og:description" content="Über Uns - Erfahren Sie mehr über uns und unsere Leistungen"/>
                <meta property="og:type" content="website"/>
                <meta property="og:locale" content="de_DE"/>
              </Helmet>
              <main id="content" class="ueber-uns">
                <AboutUs />
              </main>
            </Route>
            <Route path="/impressum">
              <Helmet>
                <meta charSet="utf-8" />
                <title>Impressum - MOHN.EVENTS</title>
                <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
                <meta name="description" content="Impressum - Impressum Mohn.Events"/>
                <meta name="robots" content="index, follow, archive"/>
                <meta property="st:section" content="Impressum - Impressum Mohn.Events"/>
                <meta name="twitter:title" content="Impressum"/>
                <meta name="twitter:description" content="Impressum - Impressum Mohn.Events"/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta property="og:url" content="https://www.mohn.events/impressum"/>
                <meta property="og:title" content="Impressum"/>
                <meta property="og:description" content="Impressum - Impressum Mohn.Events"/>
                <meta property="og:type" content="website"/>
                <meta property="og:locale" content="de_DE"/>
              </Helmet>
              <main id="content" class="impressum">
                <Imprint />
              </main>
            </Route>
            <Route path="/datenschutzerklaerung">
              <Helmet>
                <meta charSet="utf-8" />
                <title>Datenschutzerklärung - MOHN.EVENTS</title>
                <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
                <meta name="description" content="Datenschutzerklärung - Datenschutzerklärung Mohn.Events"/>
                <meta name="robots" content="index, follow, archive"/>
                <meta property="st:section" content="Datenschutzerklärung - Datenschutzerklärung Mohn.Events"/>
                <meta name="twitter:title" content="Datenschutzerklärung"/>
                <meta name="twitter:description" content="Datenschutzerklärung - Datenschutzerklärung Mohn.Events"/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta property="og:url" content="https://www.mohn.events/datenschutzerklaerung"/>
                <meta property="og:title" content="Datenschutzerklärung"/>
                <meta property="og:description" content="Datenschutzerklärung - Datenschutzerklärung Mohn.Events"/>
                <meta property="og:type" content="website"/>
                <meta property="og:locale" content="de_DE"/>
              </Helmet>
              <main id="content" class="datenschutzerklaerung">
                <Privacy />
              </main>
            </Route>
            <Route path="/">
              <main id="content" class="startseite">
                <Helmet>
                  <meta charSet="utf-8" />
                  <title>Home - MOHN.EVENTS</title>
                  <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
                  <meta name="description" content="Home - Service Übersicht von MOHN.EVENTS inklusive Referenzen"/>
                  <meta name="robots" content="index, follow, archive"/>
                  <meta property="st:section" content="Home - Service Übersicht von MOHN.EVENTS inklusive Referenzen"/>
                  <meta name="twitter:title" content="Home"/>
                  <meta name="twitter:description" content="Home - Service Übersicht von MOHN.EVENTS inklusive Referenzen"/>
                  <meta name="twitter:card" content="summary_large_image"/>
                  <meta property="og:url" content="https://www.mohn.events/"/>
                  <meta property="og:title" content="Home"/>
                  <meta property="og:description" content="Home - Service Übersicht von MOHN.EVENTS inklusive Referenzen"/>
                  <meta property="og:type" content="website"/>
                  <meta property="og:locale" content="de_DE"/>
                </Helmet>
                <Intro />
              </main>
            </Route>
            <Route path="*">
              <Error />
            </Route>
          </Switch>
      </Router>
      <Footer/>
    </div>
  );
}

export default App;