import React, { Component } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import styles from '../scss/services.module.scss';
import cn from 'classnames';

import placeholder from '../images/service-placeholder.jpg';
import prozessoptimierung from '../images/prozessoptimierung.jpg';
import markenbekanntheit from '../images/markenbekanntheit.jpg';
import ticketverkauf from '../images/ticketverkauf.jpg';
import werbung from '../images/werbung.jpg';

class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {
            services: [
                { id: 1, title: 'Markenbekanntheit', image: markenbekanntheit },
                { id: 2, title: 'Werbung', image: werbung },
                { id: 3, title: 'Prozessoptimierung', image: prozessoptimierung },
                { id: 4, title: 'Ticketverkauf', image: ticketverkauf }
            ]
        }
    }
    render() { 
        return (
            <div className={cn(styles.serviceWrapper)}>
                {this.state.services.map((service) => 
                    <div className={cn(styles.serviceItem)}>
                        <div className={cn(styles.serviceItemImage)}>
                            <LazyLoadImage
                                alt={service.title + 'Bild'}
                                effect="blur"
                                src={service.image} />
                        </div>
                        <div className={cn(styles.serviceItemTitle)}>
                            <h3>{service.title}</h3>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
 
export default Services;