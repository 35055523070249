import React, { Component } from 'react';
import Logo from '../images/mohn-logo.png';
import {
    BrowserRouter,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import FacebookIcon from '../images/facebook-icon.svg';
import MailIcon from '../images/mail-icon.svg';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuState: false,
            headerClass: '',
            navigation: [
                { id: 1, title: 'Home', link: '/' },
                { id: 2, title: 'Über Uns', link: '/ueber-uns' },
                { id: 3, title: 'Service', link: '/service' },
                { id: 5, title: 'Kontakt', link: '/kontakt' }
            ],
            social: [
                { id: 1, title: 'Facebook', link: 'https://www.facebook.com/MOHN.EVENTS/', icon: FacebookIcon },
                { id: 2, title: 'E-Mail', link: 'mailto:info@mohn.events', icon: MailIcon }
            ]
        }
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        this.handleScroll();
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    handleScroll = () => {
        let pathName = window.location.pathname;
        if( pathName == '/' ) {
            pathName = 'startseite';
        }
        if( window.scrollY >= 100 ) {
            this.setState(currentState => {
                return { headerClass: 'is-active ' + pathName.replace(/\\|\//g,'') }
            });
        } else {
            this.setState(currentState => {
                return { headerClass: pathName.replace(/\\|\//g,'') }
            });
        }
    }
    handleMenu = () => {
        const menuState = this.state.menuState;
        this.setState({ menuState: !menuState });
    }
    render() { 
        return (
            <BrowserRouter>
                <header id="menu-bar" className={this.state.headerClass}>
                    <div id="logo">
                        <a href="/">
                            <img src={Logo} alt="Mohn Events Logo"/>
                        </a>
                    </div>
                    <nav id="menu">
                        <ul className="menu horizontal">
                            {this.state.navigation.map((nav) =>
                                <li>
                                    <a href={nav.link} title={nav.title}>{nav.title}</a>
                                </li>
                            )}
                        </ul>
                    </nav>
                    <nav id="social-nav">
                        <ul className="menu horizontal">
                            {this.state.social.map((nav) =>
                                <li>
                                    <a href={nav.link} title={nav.title}><img src={nav.icon} alt={nav.title + " Icon"}/></a>
                                </li>
                            )}
                        </ul>
                    </nav>
                    <div className={(this.state.menuState) ? 'hamburger hamburger--collapse is-active' : 'hamburger hamburger--collapse is-not-active'} onClick={this.handleMenu}>
                        <div className="hamburger-box">
                            <div className="hamburger-inner"></div>
                        </div>
                    </div>
                </header>
                <div id="mobile-menu" className={(this.state.menuState) ? 'is-active' : 'is-not-active'}>
                    <nav>
                        <ul className="menu">
                            {this.state.navigation.map((nav) =>
                            <li>
                                <a href={nav.link} title={nav.title}>{nav.title}</a>
                            </li>
                            )}
                        </ul>
                    </nav>
                    <nav id="mobile-social-nav">
                        <ul className="menu horizontal">
                            {this.state.social.map((nav) =>
                                <li>
                                    <a href={nav.link} title={nav.title}><img src={nav.icon} alt={nav.title + " Icon"}/></a>
                                </li>
                            )}
                        </ul>
                    </nav>
                </div>
            </BrowserRouter>
        );
    }
}
 
export default Header;