import React, { Component } from 'react';
import startseite from '../images/startseite.jpg';

class Intro extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headerClass: ''
        }
    }
    componentDidMount() {
        let pathName = window.location.pathname;
        if( pathName == '/' ) {
            pathName = 'startseite';
            this.setState({ headerClass: 'startseite' });
        }
    }
    componentWillReceiveProps(nextProps) {
        let pathName = window.location.pathname;
        if( pathName == '/' ) {
            pathName = 'startseite';
            this.setState({ headerClass: 'startseite' });
        }
    }
    render() { 
        return (
            <header id="intro" class={this.state.headerClass}>
                <div id="spinner-wrapper">
                    <div id="spinner"></div>
                </div>
                <img src={startseite} alt="Startseite"/>
                <div className="container">
                    <div className="caption">
                        <div className="headline">
                            <h3>Ihr Professioneller</h3>
                            <h2>Marketing Experte</h2>
                        </div>
                        <p>
                            <a href="/ueber-uns" className="btn link" title="Mehr erfahren">Mehr erfahren</a>
                        </p>
                    </div>
                </div>
            </header>
        );
    }
}
 
export default Intro;