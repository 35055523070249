import React, { Component } from 'react';
import Partner from './Partner';

class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            message: ""
        }
    }
    render() { 
        return (
            <section id="about">
                <div className="container">
                    <div className="headline">
                        <h3>Unsere Story</h3>
                        <h1>Über Uns</h1>
                    </div>
                    <p>Seit fast 20 Jahren arbeiten wir für das Airbeat One Festival und unterstützen die Event - und Produktionsleitung! Die über diesen Zeitraum weitreichend geknüpften Kontakte sorgen für ein solides Netzwerk, welches uns mit den aktuellsten Trends und Neuerungen im Bereich des Eventmanagement versorgt! Um das Event noch stärker wachsen zu lassen haben wir uns mit dem Thema Social Media Marketing auseinandergesetzt und konnten sehr positive Ergebnisse erzielen! Das Wachstum der Besucherzahl der letzten Jahre spricht eine klare Sprache. Social-Media-Marketing ist das Pferd, auf das man setzen sollte. Das haben unter anderen auch der Beachclub Nethen, Hundestrand Nethen, Schnelltest Nord, Holy Taco, Caravan Oldenburg und das Oldenbora Festival erkannt, welche wir ebenfalls mit unserem Wissen und Zielgruppen orientierter Werbung unterstützen.</p>
                    <div class="headline">
                        <h3>Unsere Kunden</h3>
                    </div>
                    <p>Zu unseren Kunden zählen unter anderen Veranstaltungen wie das Airbeat One Festival, Night of Freestyle Tour, Oldenbora Festival, Indian Spirit Festival, Electric Sea Festival, Snowbeat Festival, Psychedelic Circus Festival, Und draußen tanzt der Bär Herrentags Open Air, diverse A24 on Tour Veranstaltungen. Des weiteren Stadtfeste wie das Lindenfest Ludwigslust, Hafenfest Schwerin, Altstadtfest Schwerin, Altstadtfest Hagenow, Beachclub Nethen, Schnelltest Nord, Holay Taco (HH Reeperbahn) und das Erntefest Pampow.</p>
                    <p>Zudem unterstützen wir auch den Beachclub Nethen und Hundestrand Nethen, Schnelltest Nord, Holy Taco, Caravan Oldenburg bei diversen Veranstaltungen im Oldenburger Raum und durften auch LIVE NATION BRAND PARTNERSHIP & MEDIA bei einer Liveübertragung von Gestört aber Geil (Penny goes Party) unterstützen diese zu bewerben.</p>
                    <Partner/>
                </div>
            </section>
        );
    }
}
 
export default AboutUs;