import React, { Component } from 'react';

import Services from './Services';

import Partner from './Partner';

class Service extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return (
            <section id="service">
                <div className="container">
                    <div className="headline">
                        <h3>Einzigartige</h3>
                        <h1>Dienstleistungen</h1>
                    </div>
                    <Services/>
                    <div className="headline">
                        <h2>Unsere Kunden</h2>
                    </div>
                    <Partner/>
                </div>
            </section>
        );
    }
}
 
export default Service;