import React, { Component } from 'react';

class Imprint extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return (
            <section id="imprint">
                <div className="container">
                    <div className="headline">
                        <h1>Impressum</h1>
                        <h3>Angaben gemäß § 5 TMG</h3>
                    </div>
                    <p>Oliver Mohn<br />
                    Prozessoptimierung & Zielgruppen orientierte Werbung<br />
                    Lüblower Weg 21<br />
                    19288 Warlow</p>
                    <h2>Kontakt</h2>
                    <p>Telefon: +491714167851<br />
                    E-Mail: info@mohn.events</p>
                    <h2>Umsatzsteuer-ID</h2>
                    <p>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:<br />
                    DE324625563</p>
                    <h2>EU-Streitschlichtung</h2>
                    <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
                    <h2>Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</h2>
                    <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
                </div>
            </section>
        );
    }
}
 
export default Imprint;