import React, { Component } from 'react';
import cn from 'classnames';
import styles from '../scss/blog.module.scss';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import BlogImage from '../images/blog-image-placeholder.jpg';

class Blog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blog: [
                { title: "Das ist ein Test", description: "Seit fast 20 Jahren arbeiten wir für das Airbeat One Festival und unterstützen die Event - und Produktionsleitung!", image: BlogImage, link: '/das-ist-ein-test' },
                { title: "Das ist ein Test2", description: "Seit fast 20 Jahren arbeiten wir für das Airbeat One Festival und unterstützen die Event - und Produktionsleitung!", image: BlogImage, link: '/das-ist-ein-test' }
            ]
        }
    }
    render() { 
        return (
            <section id="blog">
                <div className="container">
                    <div className="headline">
                        <h3>Neuigkeiten</h3>
                        <h1>Blog</h1>
                    </div>
                    <p>Seit fast 20 Jahren arbeiten wir für das Airbeat One Festival und unterstützen die Event - und Produktionsleitung!</p>
                    <div className={cn(styles.blogWrapper)}>
                        { this.state.blog.map( (post) =>
                            <div className={cn(styles.blogItem)}>
                                <div className={cn(styles.blogImage)}>
                                    <Link to={post.link} title={post.title}>
                                        <LazyLoadImage
                                            alt={ post.title + ' Bild'}
                                            effect="blur"
                                            src={BlogImage}
                                            className="img-responsive" />
                                    </Link>
                                </div>
                                <div className={cn(styles.blogContent)}>
                                    <h2><Link to={post.link} title={post.title}>{post.title}</Link></h2>
                                    <p>{post.description}</p>
                                    <p>
                                        <Link to={post.link} className="btn standard" title="Mehr lesen">Mehr lesen</Link>
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Blog;