import React, { Component } from 'react';
import { getCurrentYear } from './getCurrentYear';
import styles from '../scss/footer.module.scss';
import cn from 'classnames';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    getPath = () => {
        let pathName = window.location.pathname;
        if( pathName == '/' ) {
            pathName = 'startseite';
        }
        return pathName.replace(/\\|\//g,'');
    }
    render() { 
        return (
            <footer id="footer" className={(this.getPath() != 'startseite' && this.getPath() != 'kontakt') ? 'dark' : 'light'}>
                <div className={(this.getPath() != 'startseite' && this.getPath() != 'kontakt') ? 'container' : 'container-fluid'}>
                    <div className="row justify-between">
                    {(this.getPath() != 'startseite' && this.getPath() != 'kontakt') ?
                        <div className="col">
                            <span>© {getCurrentYear()} by Mohn Events</span>
                        </div>
                    :
                        ''
                    }
                        <nav id="footer-menu">
                            <ul className="menu horizontal">
                                <li>
                                    <a href="/impressum" title="Impressum">Impressum</a>
                                </li>
                                <li>
                                    <a href="/datenschutzerklaerung" title="Datenschutz">Datenschutz</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </footer>
        );
    }
}
 
export default Footer;