import validator from 'validator';
import React, { Component } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Textarea from 'react-validation/build/textarea';
import Button from 'react-validation/build/button';
import contact from '../images/startseite.jpg';

import axios from 'axios';

const API_PATH = 'https://mohn.events/mail.php';

const required = (value) => {
    if (!value.toString().trim().length) {
        return 'Pflichtfeld';
    }
  };
   
const email = (value) => {
    if (!validator.isEmail(value)) {
        return `${value} ist keine gültige E-Mail Adresse.`
    }
};

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            message: "",
            mailSent: false,
            response: ""
        }
    }
    handleSubmit = ( event ) => {
        event.preventDefault();
        console.log(this.state);
        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: { 'content-type': 'application/json' },
            data: this.state
        })
        .then(result => {
            this.setState({
                mailSent: result.data.sent,
                response: result.data.response
            })
        })
        .catch(error => this.setState({ error: error.message }));
    }
    render() { 
        return (
            <header id="intro" class={this.state.headerClass}>
                <div id="spinner-wrapper">
                    <div id="spinner"></div>
                </div>
                <img src={contact} alt="Kontakt"/>
                <div className="container">
                    <div className="headline">
                        <h3>Fragen?</h3>
                        <h1>Schreib uns!</h1>
                    </div>
                    <Form onSubmit={this.handleSubmit.bind(this)}>
                        <div className="form-group">
                            <label for="name">
                                <Input id="name" type="text" name="name" placeholder="Name*" onChange={e => this.setState({ name: e.target.value })} validations={[required]}/>
                            </label>
                        </div>
                        <div className="form-group">
                            <label for="email">
                                <Input id="email" type="email" name="email" value={this.state.email} placeholder="E-Mail*" onChange={e => this.setState({ email: e.target.value })} validations={[required, email]}/>
                            </label>
                        </div>
                        <div className="form-group">
                            <label for="message">
                                <Textarea id="message" name="message" value={this.state.message} rows="4" placeholder="Deine Nachricht*" onChange={e => this.setState({ message: e.target.value })} validations={[required]}/>
                            </label>
                        </div>
                        <p>
                            <small>
                                Mit dem Absenden des Kontaktformulars erklären Sie sich damit einverstanden, dass Ihre Daten zur Bearbeitung Ihres Anliegens verwendet werden. Weitere Informationen und Widerrufshinweise finden Sie in unserer <a href="/datenschutzerklaerung" title="Datenschutzerklärung">Datenschutzerklärung</a>.
                            </small>
                        </p>
                        <p>
                            <small>
                                Mit (*) gekennzeichnete Felder sind Pflichtfelder.
                            </small>
                        </p>
                        {!this.state.mailSent &&
                        <p>
                            <Button title="Absenden" className="btn standard">Absenden</Button>
                        </p>}
                        {this.state.mailSent &&
                            <p>{this.state.response}</p>
                        }
                    </Form>
                </div>
            </header>
        );
    }
}
 
export default Contact;